body {
    //font-family: 'Montserrat';
    overflow-x: hidden;
}

.hidden {
    display: none !important;
}

.headerRatings {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 4.5rem 0rem;
    z-index: 1;
}

.headerRatings > section, .headerRatings > header > h1 {
    width: 100%;
}

.headerRatings > section:first-of-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: rgba(0, 166, 184, 0.07);
    padding: 1.5rem 11.25rem;
    align-items: center;
}

.headerRatings > section:first-of-type > button {
    font-size: 1.3rem;
    color: #009aa7;
    font-weight: 600;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0 2rem;
    cursor: pointer;
    padding: 0.2rem 0;
}
.headerRatings > section:first-of-type > button:hover {
    border-bottom: 2px solid #009aa7;
}

.starBtns {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
}
.starBtns > button{
    background-color: transparent;
    border: none;
    padding: 0;
    width: 2rem;
    height: 2.1rem;
    margin: 0.2rem;
    cursor: pointer;
    background-image: url(/css/assets/star.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.s1:hover,
.s2:hover,
.s3:hover,
.s4:hover,
.s5:hover,
.s1:hover ~ .s2, .s1:hover ~ .s3, .s1:hover ~ .s4, .s1:hover ~ .s5,
.s2:hover ~ .s3, .s2:hover ~ .s4, .s2:hover ~ .s5,
.s3:hover ~ .s4, .s3:hover ~ .s5,
.s4:hover ~ .s5 {
    background-image: url(/css/assets/coloredStar.svg) !important;
}

.headerRatings > header > h1 {
    text-align: center;
    font-size: 2.3rem;
    color: #009aa7;
    font-weight: bold;
    margin-bottom: 4rem;
}

.ratings {
    display: flex;
    margin-top: 4.5rem;
}

.ratings > div:first-of-type {
    width: 35%;
    display: flex;
    flex-wrap: wrap;
    border-right: 1px solid #0099a726;
    align-self: flex-start;
}

.hiddenSEO {
    position: absolute;
    opacity: 0;
    width: 0;
}

.logoAndRatings {
    display: flex;
    padding: 0.8rem 1rem  0.7rem 1rem;
    margin-bottom: 2rem;
    width: 100%;
}

.logoAndRatings > img {
    align-self: flex-start;
    width: 45%;
}

.logoAndRatings > div {
    margin-left: 10%;
    width: 45%;
}

.logoAndRatings > div > div {
    display: flex;
}

.logoAndRatings > div > div > img {
    width: 20%;
}

.logoAndRatings > div > p {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 0.8rem;
    color: #324b4e;
    margin-left: 0.2rem;
}

.logoAndRatings > div > div {
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
}

.ratingAmount {
    padding: 0 1.2rem;
    width: 100%;
}

.ratingAmount > table {
    width: 100%;
}
.th {
    text-align: left;
    font-weight: bold;
    font-size: 0.7rem;
    text-transform: uppercase;
    color: #324b4e;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #0099a726;
}

.ratingAmount > table > tbody > tr > th:first-of-type {
    width: 35%;
}

.ratingAmount > table > tr > th:last-of-type, .ratingAmount table > tr > td {
    width: 65%;
}

.xStars {
   width: 100%;
   text-align: left;
   font-size: 0.8rem;
   color: #3a80be;
   border: none;
   background-color: transparent;
   outline: none;
   padding: 1.4rem 0.5rem;
   margin: 0 !important;
   cursor: pointer;
   -webkit-appearance: none;
}

tr {
    border-bottom: 1px solid #0099a726;
}

td > span {
    text-align: left;
    font-weight: bold;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #324b4e;
    margin-left: 0.2rem;
}

td > span > span {
    font-weight: 400;
    text-transform: none;
    color: #ff8c41;
}


input[type="range"] {
    -webkit-appearance: none;
    outline: none;
    overflow: hidden;
    width: 40%;
    height: 5px;
    border-radius: 0px;
    background-color: #324b4e38;
    display: inline;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    width: 0%;
    height: 5px;
    background: #ff8c41;
    box-shadow: -500px 0 0 500px #ff8c41;
  }

  .reviews {
      width: 75%;
      padding: 0 3.5rem;
  }
  .headerRatings .item {
    border-bottom: 1px solid #0099a726;
    padding-bottom: 1.4375rem;
    padding-top: 1.875rem;
  }

  .rate-header {
    display: flex;
    margin-bottom: 1.25rem;
  }

  .rate-comment {
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1.54;
    color: #324b4e;
  }

  .photo {
    align-items: center;
    background-color: #009aa7;
    border-radius: 100%;
    color: #fff;
    display: flex;
    font-size: 1.3125rem;
    font-weight: 300;
    height: 2.75rem;
    justify-content: center;
    margin-right: 1.5625rem;
    min-width: 2.75rem;
    overflow: hidden;
    width: 2.75rem;

  }

  .name {
    font-weight: 500;
    margin-bottom: .4375rem;
    color: #324b4e;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1;
    text-align: left;
  }

  .rate-order-verified {
    color: #3a80be;
    font-size: .6875rem;
    font-weight: 700;
    margin-left: 1.5625rem;
  }

  .stars {
      display: flex;
      margin-bottom: 1rem;
  }

  .stars > div {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 1rem;
    margin-right: 3px;
    opacity: 1;
    transform: translateX(0);
    width: 1.0625rem;
    background-image: url(/css/assets/coloredStar.svg);
  }

  .date {
    font-size: .8125rem;
    font-weight: 500;
    opacity: .5;
  }

  .productSelect {
      -webkit-appearance: none;
      background-color: #324b4e0c;
      padding: 0.5rem 1.5rem;
      border: none;
      border-radius: 0;
      outline: none;
      color:#324b4e;
      margin-left: 0.5rem;
      width: 14rem;
      background-image: url(/css/assets/triangle.svg);
      background-size: 8px 12px;
      background-repeat: no-repeat;
      background-position: 90% center;
  }

  .form {
      width: 100%;
      transition: height 1s;
  }

  .form > p {
    font-size: 0.95rem;
    color: #324b4e;
    font-weight: 600;
    margin-top: 4rem;
    margin-bottom: 1.5rem;
  }

  .form > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  }

  .form > div > div:nth-of-type(1),  .form > div > div:nth-of-type(2),  .form > div > div:nth-of-type(3),  .form > div > div:nth-of-type(4), .form > div > div:nth-of-type(5), .form > div > div:nth-of-type(6) {
    width: 47%;
  }

.form > div > div:nth-of-type(8), .form > div > div:nth-of-type(11) {
    width: 100%;
  }

  .form > div > div:nth-of-type(9),  .form > div > div:nth-of-type(10) {
    width: 46%;
}

.ratingSelect {
    width: 47% !important;
}

.form > div > div:nth-of-type(11) > textarea {
    height: 10rem;
    resize: none;
}

  .labelRatingForm {
      width: 100%;
      display: block;
      font-size: 0.8rem;
      color: #324b4e86;
      margin-top: 1.5rem;
  }

  .form > div > div > input, .form > div > div > select, .form > div > div:nth-of-type(11) > textarea {
      width: 100%;
      padding: 1rem 0rem 1rem 1rem;
      font-size: 1rem;
      background-color: rgba(255, 255, 255, 0.7);
      border: none;
      outline: none;
      margin-bottom: 0.2rem;
      border: 1px solid rgba(255, 255, 255, 0.7);
  }

  .form > div > div > select {
      -webkit-appearance: none;
      background-image: url(/css/assets/triangle.svg);
      background-size: 8px 12px;
      background-repeat: no-repeat;
      background-position: 90% center;
  }

  .formStars {
      margin-top: 4rem;
      margin-bottom: 1.5rem;
  }

  .customFileUpload {
    width: 100%;
    padding: 1.2rem 0rem 1.2rem 0rem;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    outline: none;
    margin-bottom: 0.3rem;
    color: #324b4e;
    font-size: 0.8rem;
    text-transform: uppercase;
    text-align: center;
    align-self: center;
    margin-top: 2.9rem;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background-size: cover;
  }

  input[type="file"] {
    display: none;
}

.form > div > div:nth-of-type(12) {
    padding-top: 1.2rem;
}
.privacyCheck input {
    width: 0.8rem !important;
}
.privacyCheck {
    margin-top: 0.5rem;
    display: flex;
    width: 100%;
}

.privacyCheck label {
    font-size: 0.8rem;
    color: #324b4e;
    margin-left: 0.5rem;
}

.sendBtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.sendBtn input{
    background-color: #009aa7 !important;
    color: white;
    width: 240px !important;
    cursor: pointer;
    transition: opacity 0.5s;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 0.8rem;
}

.sendBtn input:hover {
    opacity: 50%;
}

.headerRatings .tooltip, .mobileForm .tooltip {
    position: relative;
    display: inline-block;
    transition: color 0.3s;
    opacity: 1;
  }

.headerRatings .tooltip:hover, .mobileForm .tooltip:hover {
    color: #009aa7;
  }

.headerRatings .tooltip .tooltiptext, .mobileForm .tooltip .tooltiptext {
    visibility: hidden;
    width: 29rem;
    background-color: rgba(0, 0, 0, 0.863);
    color: #fff;
    text-align: center;
    padding: 2rem;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }

.headerRatings .tooltip .tooltiptext::after, .mobileForm .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    margin-left: -5px;
    border-width: 5px;
  }

.headerRatings .tooltip:hover .tooltiptext, .mobileForm .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .arrowTooltip {
      position: absolute;
      top: 57%;
      left: 39%;
  }

  .firstImgTooltip{
    margin: 10% 0% 10% 13%;
      max-height: 200px;
  }

  .secondImgTooltip {
      margin-right: 50%;
      max-height: 100px;
  }

  .thanks {
    text-align: center;
    display: none;
    background-color: rgba(0, 166, 184, 0.07);
    padding: 2rem 0rem;
    width: 100%;
  }

  .thanks > p:first-of-type{
    font-size: 1.4rem;
    font-weight: 600;
    color: #009aa7;
    margin-block-end: 0;
    margin-bottom: 0.8rem;
  }

  .thanks > p:last-of-type {
    font-size: 1.4rem;
    font-weight: 300;
  }

  .hiddenAccordeon {
    height: 0;
    overflow: hidden;
  }

  .rate-answer {
      padding-left: 10%;
      margin-top: 2rem;
  }

  .rate-answer > div:first-of-type {
      margin-bottom: 1rem;
      border-left: 2px solid #0099a79d;
      padding-left: 1rem;
  }

  .rate-answer > div:last-of-type {
    border-left: 2px solid #D9F2F5;
    padding-left: 1rem;
    width: 90%;
  }

  .rate-answer > div:first-of-type > img {
      width: 4rem;
      margin-bottom: 0.2rem;
  }

  .mobileRatingText {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #324b4e;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 2rem;
}
    .moreInfoSpan {
        color: #009aa7;
        font-weight: normal;
        text-decoration: underline;
    }

    .mobileForm {
        display: none;
    }

    .markWrongField {
        border: 1px solid red;
    }

  @media (max-width: 975px) {
    .headerRatings {
        padding: 2rem 1.6rem;
    }

    .headerRatings > section:first-of-type {
        padding: 2rem 1rem;
    }

    .headerRatings > section:first-of-type > button {
        font-size: 1.3rem;
        width: 100%;
        margin: 0.5rem 0rem;
        padding: 0.2rem 0;
    }

    .headerRatings > section:first-of-type > button:hover {
        border-bottom: none;
    }

    .headerRatings > header > h1 {
        font-size: 1.1rem;
        margin-bottom: 2rem;
    }

    .ratings {
        margin-top: 1.8rem;
        flex-wrap: wrap;
    }

    .ratings > div:first-of-type {
        width: 100%;
        border-right: none;
    }

    .hiddenSEO {
        position: absolute;
        opacity: 0;
    }

    .logoAndRatings {
        background-color: transparent;
        padding: 0;
        margin: 2rem auto 4rem auto;
        width: 100%;
        max-width: 350px;
    }

    .logoAndRatings > img {
        align-self: flex-start;
        width: 45%;
    }

    .logoAndRatings > div {
        margin-left: 10%;
        width: 45%;
    }

    .logoAndRatings > div > p {
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 0.8rem;
        color: #324b4e;
    }

    .logoAndRatings > div > div {
        margin-bottom: 0.3rem;
        margin-top: 0.3rem;
    }

    .ratingAmount {
        padding: 0rem;
        width: 100%;
    }

    .ratingAmount > table {
        width: 100%;
    }

    td {
        background-image: url(/css/assets/arrow-right.svg);
        background-repeat: no-repeat;
        background-position: 90% center;
        cursor: pointer;
    }

    .reviews {
          width: 100%;
          padding: 0;
    }

    .rate-order-verified {
        margin-left: 0;
        margin-top: 0.5rem;
        display: block;
        width: 100%;
    }

    .productSelect {
        padding: 0.8rem 1.5rem;
        margin: 2rem 0rem;
        width: 95%;
    }

    .form {
        display: none;
    }

    .thanks {
        text-align: center;
        display: none;
        padding: 1rem;
    }

    .thanks > p:first-of-type{
        font-size: 1rem;
    }

    .thanks > p:last-of-type {
        font-size: 0.9rem;
    }

    .mobileForm {
        display: block;
        margin-top: 2rem;
        z-index: 3;
        background-color: rgba(0, 166, 184, 0.07);
        padding: 0;
        margin-bottom: 6rem;
    }

    .mobileForm > div {
        padding: 1.5rem;
    }

    .mobileForm > div > div {
        width: 90%;
    }

    .mobileForm > div > div > input, .mobileForm > div > div > select, .mobileForm > div > div:nth-of-type(11) > textarea {
        width: 100%;
        padding: 1rem 0rem 1rem 1rem;
        font-size: 1rem;
        background-color: rgba(255, 255, 255, 0.7);
        border: none;
        outline: none;
        margin-bottom: 1.5rem;
    }

    .mobileForm > div > div > select {
        -webkit-appearance: none;
        background-image: url(/css/assets/triangle.svg);
        background-size: 8px 12px;
        background-repeat: no-repeat;
        background-position: 90% center;
        width: 100%;
    }

    .mobileForm > div > div:nth-of-type(11) > textarea {
        height: 15rem;
        resize: none;
    }

    .mobileForm .tooltip .tooltiptext {
        left: -5rem;
        width: 20rem;
      }

      .mobileRatingMenu {
          display: flex;
          justify-content: space-between;
          height: 3.5rem;
          background-color: white;
          padding: 0 1.5rem 0 1.5rem;
          border-bottom: 1px solid #f3f6f7;
      }

      .mobileRatingMenu p {
          color: #009aa7;
          font-weight: 600;
          align-self: center;
          font-size: 1rem;
      }

      .mobileRatingMenu button {
          align-self: center;
          border: none;
          background-color: transparent;
          font-size: 1rem;

      }

      .ratingSelect {
        width: 90% !important;
    }

    .ratingSelect > label, .ratingSelect > select {
        margin-left: 0rem;
    }

    .formStars {
        justify-content: flex-end;
    }

    .hiddenMobile {
        display: none;
    }

    .customFileUpload {
        margin-left: 0;
        width: 100%;
        margin-bottom: 2rem;
        margin-top: -1rem;
    }

    .mobileRatingText1 {
        margin: 1.5rem 1.5rem;
    }

    .privacyCheck {
        margin-bottom: 0;
    }

    .sendBtn {
        margin-top: 3rem;
    }

    .filterMargin {
        margin-top: 3rem;
    }

    .arrowTooltip {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: rotate(-35deg);
    }

    .firstImgTooltip{
      margin: 10% 0% 10% 20%;
    }

    .secondImgTooltip {
        margin-right: 30%;
    }

  }
.comment-loader {
    text-align: center;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #009aa7;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

