
$f-size-base: 10px!default;
//$f-size-base: 16px!default;

@function strip-units($number) {
    @return $number / ($number * 0 + 1);
}
@function rem($font-size){
    $rem: strip-units($font-size / $f-size-base);
    @return #{$rem}rem;
}
html{
    font-size: 10px;
}

