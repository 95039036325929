.switch {
	position: relative;
	display: inline-flex;
	align-items: center;
	flex-direction: row-reverse;
	cursor: pointer;
	&:hover {
	    input {
			& + .slider:before {
				background-color: #ddd;
			}
			&:checked {
	        	& + .slider:before {
	          		background-color: lighten($blue, 15%);
	        	}
	      	}
	    }
	}
	input {
		display: none;
		&:checked {
			&+.slider {
				&:before {
					transform: translateX(34px);
					background-color: $blue;
				}
				&+label {
					opacity: 1;
				}
			}
		}
		&:disabled + .slider {
			opacity: 0.5;
			&+label {
				opacity: 0.5;
			}
		}
	}
  	.slider {
	    width: 55px;
	    min-width: 55px;
	    height: 24px;
	    position: relative;
	    cursor: pointer;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: rgba($gray-dark, 0.1);
	    border-radius: 4px;
	    transition: all 0.4s linear;
	    &:before {
			position: absolute;
			content: "";
			height: 26px;
			width: 26px;
			left: -3px;
			bottom: -1px;
			background-color: #ccc;
			transition: all 0.4s ease;
			border-radius: 6px;
	    }
  	}
	label {
		margin-right: 10px;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		height: 20px;
		font-size: 11px;
		cursor: pointer;
		color: $gray-dark;
		opacity: 0.5;
		transition: all 0.4s linear;
	}
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.videos-wrapper {
	padding: 60px 60px 40px 32px;
	.videos-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		.videos-supertitle {
			font-size: 25px;
			font-weight: bold;
			text-transform: uppercase;
			color: $gray-dark;
			opacity: 0.5;
		}	
		.videos-autoplay-wrapper{
			align-self: flex-start;
		}
	}
	.videos-section {
		.videos-section-title {
			display: flex;
			align-items: center;
			font-size: 17px;
			font-weight: bold;
			color: $gray-dark;
			&:after {
				content: '';
				margin-left: 20px;
				height: 1px;
				flex-grow: 1;
				background-color: $c-gray2;
			}
		}
		.videos-section-list {
			display: flex;
			flex-wrap: wrap;
			padding: 40px 0 20px 0;
			margin-left: -10px;
			width: calc(100% + 10px + 65px);
			.videos-section-item {
				margin-bottom: 60px;
				margin-right: 65px;
				padding: 0 20px;
				width: calc(25% - 65px);
				min-width: calc(25% - 65px);
			}
		}
	}
	.videos-section-item {
		.video-wrapper {
			position: relative;
			width: calc(100% + 40px);
			margin-left: -20px;
			margin-bottom: 10px;
			padding-top: 67%;
			cursor: pointer;
			&:hover {
				&.isLoaded {
					img {
						display: none;
					}
					iframe {
						display: block;
					}
				}
				.video-container {
					&:before, &:after {
						display: block;
					}
				}
			}
			&.isLoaded {
				.video-container {
					&:before, &:after {
						display: none !important;
					}
				}
			}
			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
			.video-container {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				&:before {
					content: '';
					position: absolute;
					display: none;
					z-index: 3;
					width: 100%;
					height: 100%;
					background-color: rgba(255,255,255,0.8);
					border-radius: 15px;
				}
				&:after {
					content: '';
					position: absolute;
					display: none;
					z-index: 3;
					top: calc(50% - 15px);
					left: calc(50% - 15px);
					width: 30px;
					height: 30px;
					border-radius: 100%;
					border: 3px solid #ccc;
					border-top: 3px solid $blue;
					animation: spin 0.8s ease infinite;
				}
			}
			img {
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				object-fit: cover;
				object-position: center;
				width: 100%;
				height: 100%;
				border-radius: 15px;
			}
			iframe {
				display: none;
				border-radius: 15px;
				max-width: 100%;
				max-height: 100%;
			}
			.video-duration {
				position: absolute;
				z-index: 4;
				bottom: 15px;
				right: 15px;
				pointer-events: none;
				background-color: rgba($gray-dark, 0.75);
				height: 23px;
				padding: 0 10px;
				display: flex;
				align-items: center;
				border-radius: 6px;
				color: #fff;
				font-size: 13px;
				font-weight: 500;
			}
		}
		.video-title {
			font-size: 17px;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 1.47;
			color: $gray-dark;
		}
		.video-subtitle {
			font-size: 17px;
			line-height: 1.47;
			color: $gray-dark;
		}
		.video-family {
			margin-top: 10px;
			display: flex;
			align-items: center;
			.video-family-text {
				font-size: 13px;
				color: $gray-dark;
				opacity: 0.5;
				line-height: 1.5;
			}
			.video-family-icon {
				margin-left: 10px;
				width: 10px;
				height: 10px;
				border-radius: 100%;
			}
		}
	}
	@include media-breakpoint-down(md) {
        padding: 20px $lateral-space-sm;
        .videos-header {
        	padding-top: 20px;
			.videos-supertitle {
				font-size: 19px;
			}
			.videos-autoplay-wrapper {
				display: none;
			}
		}
		.videos-section {
			.videos-section-title {
				font-size: 15px;
			}
			.videos-section-list {
				width: calc(100% + 10px + 20px);
				padding-top: 20px;
				.videos-section-item {
					margin-bottom: 30px;
					margin-right: 10px;
					padding: 0 10px;
					width: calc(50% - 10px) !important;
					min-width: calc(50% - 10px) !important;
				}
			}
		}
		.videos-section-item {
			.video-wrapper {
				position: relative;
				width: calc(100% + 20px);
				margin-left: -10px;
			}
			.video-title {
				font-size: 15px;
				line-height: 1.2;
			}
			.video-subtitle {
				font-size: 15px;
				line-height: 1.2;
			}
			.video-family {
				margin-top: 5px;
			}
			.video-family-text {
				font-size: 11px;
			}
		}
    }
}
