.hashtags{
    .hashtags-header{
        font-size: rem(17px);
        margin-bottom: rem(20px);
        font-weight: normal;
        .hashtags-title{
            color: #333;
            font-weight: normal;
            font-family: 'Montserrat-Medium';
        }
    }

    .hashtags-body{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: rem(11px);
    }

    .hashtag{
        background-color: white;
        color: $c-fama;
        white-space: nowrap;
        font-size: rem(12px);
        padding: rem(6px) rem(12px);
        border-radius: rem(6px);
        margin-right: rem(8px);
        margin-bottom: rem(8px);
        transition: background-color $t-fast linear;
        &:hover {
            background-color: #f4f4f4;
        }
        &:before{
            content: '#';
        }

        &.is-active{
            background-color: $c-fama;
            color: $white;
            pointer-events: none;
        }
    }
}
body.famaliving .hashtags {
    .hashtag {
        color: $c-famaliving;
        &.is-active {
            background-color: $c-famaliving;
            color: $white;
        }
    }

}

