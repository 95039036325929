.sidebar-categories
{
    @media (min-width: 992px) {
    //@include media-breakpoint-up(md) { // Desktop
        display: flex;
        flex-direction: column;

        .item {
            border-left: 1px solid #d6dcde;
            position: relative;
            &:before, &:after {
                content: '';
                position: absolute;
                left: 0;
                top: rem(18px);
                transform: translate(-50%, -50%);
                border-radius: 100%;
                transition: all $t-normal $easeOutCubic;
            }
            &:before {
                width: 8px;
                height: 8px;
                background-color: #d6dcde;
                z-index: 2;
            }
            &:after {
                width: 0;
                height: 0;
                background-color: #fff;
                border: 1px solid $c-fama;
                z-index: 1;
            }
            &.is-active {
                color: $c-fama;
                text-transform: uppercase;
                &:before {
                    border: 1px solid #fff;
                    background-color: $c-fama;
                }
                &:after {
                    width: 12px;
                    height: 12px;
                }
            }
            &.has-sublist {
                position: relative;
                display: flex;
                align-items: center;
                padding-right: rem(30px);
                .arrow-dropdown {
                    position: absolute;
                    right: 0;
                    //top: rem(15px);
                    top: rem(20px);
                    transform: translateY(-50%);
                    width: rem(30px);
                    height: rem(30px);
                    border-radius: 100%;
                    cursor: pointer;
                    transition: background-color $t-fast $easeOutCubic;
                    &:hover {
                        &:before {
                            opacity: 1;
                            height: 100%;
                            width: 100%;
                            transition: opacity $t-fast linear 0s, width $t-normal $easeOutCubic 0s, height $t-normal $easeOutCubic 0s;
                        }
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        height: 0;
                        width: 0;
                        border-radius: 100%;
                        transform-origin: center;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        background-color: #fff;
                        transition: opacity $t-fast linear 0s, width $t-normal $easeOutCubic 0s, height $t-normal $easeOutCubic 0s;
                    }
                    &:after {
                        content: '';
                        display: inline-block;
                        position: relative;
                        //top: calc(8px);
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transform-origin: center;
                        width: 0;
                        height: 5px;
                        border-top: 4px solid $c-gray2;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        transition: transform $t-fast linear 0.2s;
                    }
                }
            }
            .item-inner {
                padding: rem(10px) 0 rem(10px) rem(25px);
                display: block;
                cursor: pointer;
                font-size: rem(15px);
                font-weight: normal;
                font-family: 'Montserrat-SemiBold';
                color: $gray-dark;
                position: relative;
                transition: all 0.2s linear;
                &:hover {
                    color: lighten($c-fama, 5%);
                }
            }

            &.isSelected {
                .item-inner {
                    color: $c-fama;
                    text-transform: uppercase;
                }
            }

            &.isOpen {
                &.has-sublist {
                    .arrow-dropdown {
                        &:before {
                            opacity: 1;
                            height: 100%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                        }
                        &:after {
                            transform: scaleY(-1) translate(-50%, 50%);
                        }
                    }
                }
                &+.subitem-list {
                    transition: height $t-fast $easeOutCubic 0s;
                    .subitem {
                        opacity: 1;
                        transform: translateX(0);
                        $delay: 0.1s;
                        @for $i from 1 through 10 {
                            &:nth-child(#{$i}) {
                                transition: opacity $t-fast linear calc((#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc((#{$i} * #{$delay}));
                            }
                        }
                    }
                }
            }
        }

        .subitem-list {
            border-left: 1px dashed #d6dcde;
            height: 0;
            overflow: hidden;
            transition: height $t-fast $easeOutCubic 0.2s;
            .subitem {
                opacity: 0;
                transform: translateX(#{rem(-25px)});
                transition: opacity $t-fast linear 0s, transform 0s linear 0.2s;
                &.is-active {
                    .subitem-inner {
                        color: $c-fama;
                        text-transform: uppercase;
                        &:before {
                            opacity: 0;
                            background-image: url('/css/assets/arrow-grey.svg');
                        }
                        &:after {
                            opacity: 1;
                            background-image: url('/css/assets/arrow-blue-fl.svg');
                        }
                    }
                }
                .subitem-inner {
                    text-transform: uppercase;
                    padding: rem(10px) 0 rem(10px) rem(50px);
                    display: block;
                    cursor: pointer;
                    font-size: rem(13px);
                    font-weight: normal;
                    font-family: 'Montserrat-SemiBold';
                    color: $gray-dark;
                    position: relative;
                    transition: all 0.2s linear;
                    &:before, &:after {
                        content: '';
                        position: absolute;
                        left: rem(25px);
                        top: rem(13px);
                        height: 7px;
                        width: 10px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        transition: all $t-fast linear;
                    }
                    &:before {
                        opacity: 1;
                        background-image: url('/css/assets/arrow-grey.svg');
                    }
                    &:after {
                        opacity: 0;
                        background-image: url('/css/assets/arrow-blue-fl.svg');
                    }
                    &:hover {
                        color: lighten($c-fama, 5%);
                        &:before {
                            opacity: 0;
                            background-image: url('/css/assets/arrow-grey.svg');
                        }
                        &:after {
                            opacity: 1;
                            background-image: url('/css/assets/arrow-blue-fl.svg');
                        }
                    }
                    img {
                        display: none;
                    }
                }
            }
        }
    }

    //@include media-breakpoint-down(md) { // Mobile
    @media (max-width: 991px) {
        position: relative;
        display: flex;
        align-items: flex-start;   
        overflow-x: auto;
        padding-left: $lateral-space-sm;

        .item
        {
            //padding: 25px 13px 9px;
            padding: 25px 13px 5px;
            border-bottom: 2px solid transparent;
            white-space: nowrap;
            margin-bottom: 0;
            transition: margin $t-fast $easeOutCubic 0.2s;
            .item-inner
            {
                font-size: 15px;
                font-weight: normal;
                color: $gray-dark;
            }


            &.is-active
            {
                border-bottom: 2px solid $c-fama;
            }

            &.isOpen {
                margin-bottom: rem(220px);
                transition: margin $t-fast $easeOutCubic 0s;
                .item-inner {
                    &:after {
                        display: none;
                    }
                }
                &+.subitem-list {
                    display: flex;
                    align-items: flex-start;
                    padding-top: rem(40px);
                    height: rem(220px) !important;
                    transition: height $t-fast $easeOutCubic 0s;
                    &:before {
                        content: '';
                        position: fixed;
                        left: $lateral-space-sm;
                        bottom: 0;
                        height: 1px;
                        width: calc(100% - (#{$lateral-space-sm} * 2));
                        background-color: $c-gray2;
                    }
                    .subitem {
                        .subitem-inner {
                            opacity: 1;
                            display: block;
                            transform: translateX(0);
                        }
                        $delay: 0.1s;
                        @for $i from 1 through 10 {
                            &:nth-child(#{$i}) {
                                .subitem-inner {
                                    transition: opacity $t-fast linear calc((#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc((#{$i} * #{$delay}));
                                }
                            }
                        }
                    }
                }
            }
        }
        .subitem-list {
            display: flex;
            position: fixed;
            width: 100%;
            left: 0;
            padding: 0 $lateral-space-sm;
            top: rem(51px);
            overflow: auto;
            transition: height $t-fast $easeOutCubic 0.2s;
            .subitem {
                margin-right: rem(15px);
                display: flex;
                align-items: center;
                &:last-child {
                    .subitem-inner {
                        padding-right: $lateral-space-sm;
                    }
                }
                .subitem-inner {
                    display: none;
                    padding: 0 rem(5px);
                    line-height: 1;
                    color: $gray-dark;
                    opacity: 0;
                    transform: translateX(#{rem(-25px)});
                    transition: opacity $t-fast linear 0s, transform 0s linear 0.2s;
                    img {
                        height: rem(125px);
                        width: rem(125px);
                        margin-bottom: rem(6px);
                        margin-left: rem(-5px);
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }
        .subitem-list::-webkit-scrollbar {
            display: none;
        }
    }

}

.sidebar-categories::-webkit-scrollbar {
    display: none;
}

body.famaliving .sidebar-categories {
    @include media-breakpoint-up(md) { // Desktop
        .item {
            &:after {
                border: 1px solid $c-famaliving;
            }
            &.is-active {
                color: $c-famaliving;
                &:before {
                    background-color: $c-famaliving;
                }
                .item-inner{
                    color: $c-famaliving;
                    font-weight: normal;
                    font-family: 'Montserrat-SemiBold';
                }
                h1{
                    color: $c-famaliving;
                    font-size: inherit;
                    //font-family: inherit;
                    //font-weight: inherit;
                    font-weight: normal;
                    font-family: 'Montserrat-SemiBold';
                    margin: 0;
                }
            }
            .item-inner:hover {
                color: lighten($c-famaliving, 5%);
            }
            &.isSelected .item-inner {
                color: $c-famaliving;
            }
        }
        .subitem-list .subitem {
            &.is-active .subitem-inner {
                color: $c-famaliving;
                h1{
                    font-size: inherit;
                    font-family: 'Montserrat-SemiBold';
                    font-weight: normal;
                    margin: 0;
                }
            }
            .subitem-inner:hover {
                color: lighten($c-famaliving, 5%);
            }
        }
    }
    @include media-breakpoint-down(md) { // Mobile
        .item.is-active {
            border-bottom: 2px solid $c-famaliving;
        }
    }
}
