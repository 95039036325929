.modal-finder
{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(white, 0.95);
    z-index: 10;
    overflow-y: auto;
    color: $gray-dark;

    a{
        color: #666;

        &:hover{
            color: #999;
        }
    }

    .wrapp
    {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;      
        width: 80%;
        margin: auto;
        padding-top: 5rem;

        label
        {
            font-size: 1.93rem;
            font-family: Arvo, Arial, Tahoma;
            text-transform: uppercase;
            display: flex;
            align-items: center;

            img
            {
                margin-right: 0.5rem;
            }
        }
        input
        {
            font-size: $f-size-big-title;
            border: none;
            outline: none;
            box-shadow: none;
            background-color: transparent;
            width: 100%;
            display: block;
            font-weight: 500;
            margin-left: 2rem;
            margin-bottom: 2.5rem;
        }

        .close
        {
            font-size: 2.5rem;
            position: absolute;
            top: 4rem;
            right: 0;
        }

        .results
        {
            .results-title
            {
                font-family: Arvo, Arial, Tahoma;
                font-size: $f-size-medium;
                font-weight: bold;
                text-transform: uppercase;
            }

            .item
            {
                .image,
                img.image
                {
                    width: 90px;
                    height: 60px;
                    object-fit: cover;
                    margin-right: 1.5rem;
                }

                font-size: 25px;

                padding: 15px 0;

            }
        }

    }
}
