.sidebar
{
    z-index: 3;
    >.item
    {
        display: none;
    }

    .container
    {
        position: relative;
    }

    .header-box
    {
        width: 100%;
        position: relative;
        .new-label
        {
            position: absolute;
            left: 0;
            bottom: 100%;
            background-color: $c-fama;
            color: #fff;
            font-size: rem(15px);
            font-weight: bold;
            text-transform: uppercase;
            padding: 3px rem(27px);
        }
        .header-box-content
        {
            background-color: $gray;
            height: rem(125px);
            padding-top: rem(13px);
            padding-left: rem(20px);
            padding-right: rem(25px);

            h1
            {
                font-size: $f-size-big-title;
                font-weight: bold;
                font-family: Arvo, Arial, Tahoma;
                margin-bottom: rem(22px);
                line-height: 1;
            }

            .valorations
            {
                color: $pink;
                font-size: $f-size-small;
                font-weight: 500;
                justify-self: flex-start;
                text-decoration: underline;
                cursor: pointer;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    @include media-breakpoint-down(sm){
        > .prev-item,
        > .next-item
        {
            display: none
        }
        .header-box {
            .new-label
            {
                bottom: 0;
                padding: 3px rem(20px);
            }
            .header-box-content {
                height: rem(145px);
                h1 {
                    font-size: rem(25px);
                    white-space: nowrap;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    .control-readmore {
                        display: none;
                        height: rem(20px);
                        text-transform: uppercase;
                        border-radius: rem(10px);
                        padding: 0 rem(10px);
                        background-color: #fff;
                        display: inline-flex;
                        align-items: center;
                        font-weight: bold;
                        font-size: rem(11px);
                        line-height: 1;
                        color: $gray-dark;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md){
        width: rem(385px);
        padding-top: rem(50px);
        padding-left: rem(27px);
        position: sticky;
        top: rem(50px);
        display: block;
        > .item
        {
            font-size: $f-size-normal-body;
            font-weight: 500;
            min-height: 40px;
            display: flex;
            align-items: center;
            padding: 10px 10px 10px 20px;
            margin: 0;
            border-left: $c-gray2 1px solid;
            transition: all $t-fast ease-in-out;
            position: relative;
            width: rem(425px);
            pointer-events: none;
            &:before
            {
                content: '';
                display: block;
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 0;
                transform: translate(calc(-50% - 0.5px), -50%);
                height: rem(8px);
                width: rem(8px);
                border-radius: 50%;
                background-color: $c-gray2;
                transition: all $t-fast ease-in-out;
            }

            &:after
            {
                content: '';
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 0;
                transform: translate(calc(-50% - 0.5px), -50%) scale(5);
                opacity: 0;
                height: rem(12px);
                width: rem(12px);
                border: 1px solid $c-gray2;
                background-color: #fff;
                border-radius: 100%;
                transition: opacity $t-fast linear 0s, transform 0s linear 0.2s;
            }

            > a
            {
                color: $gray-dark;             
                transition: color $t-fast linear;
                pointer-events: all;
                &:hover
                {
                    color: $c-fama;
                }
            }

            &.is-active
            {            
                font-size: rem(23px);
                font-weight: bold;
                pointer-events: none;

                &:before
                {
                    height: rem(6px);
                    width: rem(6px);
                    background-color: $gray-dark;
                }

                &:after {
                    transform: scale(1) translate(calc(-50% - 0.5px), -50%);
                    opacity: 1;
                    transition: opacity $t-fast linear 0s, transform $t-normal $easeOutCubic 0s;
                }
            }
        }

        .header-box
        {
            position: absolute;
            top: rem(-125px);
            left: 0;
            z-index: 10;
            border-bottom: 1px solid $gray;
            transform: translateY(0);
            transition: transform $t-fast $easeOutCubic 0s;
            .header-box-content
            {
                background-color: rgba(white, 0.95);
                width: 100%;
                padding-left: rem(23px);
                padding-right: rem(30px);

                .header-price-container {
                    display: none !important;
                }
            }
        }

        .prev-item,
        .next-item
        {
            margin: rem(23px) 0 rem(23px) rem(-8px);
            display: inline-block;
            transform: scaleY(1.1);
            cursor: pointer;
            transition: transform $t-fast ease-in-out;

            img
            {
                transform-origin: center;
            }
        }
        .prev-item
        {
            transform-origin: bottom;
            &:hover
            {
                transform: scaleY(1.3);
            }
            img
            {
                transform: rotate(-90deg);
            }
        }
        .next-item
        {
            transform-origin: top;
            &:hover
            {
                transform: scaleY(1.3);
            }

            img
            {
                transform: rotate(90deg);
            }
        }
    }
}
body.famaliving .sidebar
{
    .header-box .new-label
    {
        background-color: $c-famaliving;
    }

    @include media-breakpoint-up(md){
        > .item > a:hover
        {
            color: $c-famaliving;
        }
    }
}
