.modules-menu {
    margin-bottom: 145px;

    @include media-breakpoint-down(sm) {
        margin-bottom: rem(61px);
    }
}

.modules-normal {
    margin-bottom: rem(200px);

    .module-full {
        margin-bottom: rem(145px);

        @include media-breakpoint-down(sm) {
            margin-bottom: rem(61px);
        }
    }

    .module-title {
        font-size: $f-size-big-title;
        font-weight: bold;

        @include media-breakpoint-down(sm) {
            font-size: rem(17px);


        }
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: rem(20px);
        .deco-line-both {
            padding-left: rem(20px);
        }
    }

}

.famaliving{
    .module{
        .presentation-container > .module-row .presentation-price-container {
            .price-product{
                color: #c70087 !important;
            }
        }
    }
}

.module {
    position: relative;

    .module-header {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            border-style: solid;
            border-color: $c-gray2;
            border-width: 0;
            z-index: 2;
        }
    }

    .module-wrap {
        max-width: unset !important;
        width: 100% !important;
    }

    .popup-360 {
        position: fixed;
        z-index: 1000;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.9);
        visibility: hidden;
        opacity: 0;
        transition: opacity $t-fast linear 0.4s, visibility 0s linear 0.4s;

        &.isVisible {
            opacity: 1;
            visibility: visible;
            transition: opacity $t-fast linear 0s, visibility 0s linear 0s;

            .container-360 {
                opacity: 1;
                visibility: visible;
                transform: translate(-50%, -50%);
                transition: opacity $t-fast linear 0.2s, visibility 0s linear 0.2s, transform $t-normal $easeOutCubic 0.2s;
            }
        }

        .container-360 {
            position: absolute;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            width: rem(1280px);
            height: rem(700px);
            max-width: 90%;
            max-height: 90%;
            top: 47%;
            left: 50%;
            opacity: 0;
            visibility: hidden;
            transform: translate(-50%, calc(-50% - 5rem));
            transition: opacity $t-fast linear 0s, visibility 0s linear 0.4s, transform $t-normal $easeOutCubic 0s;

            .header-360 {
                height: rem(60px);
                display: flex;
                align-items: center;
                background-color: $gray;

                .title-360 {
                    flex-grow: 1;
                    font-size: rem(27px);
                    display: flex;
                    align-items: baseline;
                    padding: 0 rem(25px);
                    color: $c-fama;

                    &:before {
                        content: '';
                        width: rem(95px);
                        height: rem(30px);
                        margin-right: rem(20px);
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        background-image: url('/css/assets/fama-logo.png');
                    }
                }

                .close-360 {
                    display: flex;
                    align-items: center;
                    padding-left: rem(10px);
                    width: rem(140px);
                    height: 100%;
                    min-width: rem(140px);
                    font-weight: 500;
                    font-size: rem(15px);
                    text-transform: uppercase;
                    color: rgba($gray-dark, 0.5);
                    cursor: pointer;
                    position: relative;
                    transition: all $t-fast linear;

                    &:before {
                        content: '';
                        position: relative;
                        margin-right: rem(15px);
                        width: rem(17px);
                        min-width: rem(17px);
                        height: rem(17px);
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        background-image: url('/css/assets/x-air.svg');
                    }

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.05);
                    }
                }
            }

            .body-360 {
                position: relative;
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                iframe {
                    width: 100%;
                    height: 100%;
                    border: 0 !important;
                    outline: 0 !important;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: rem(20px);
                    left: 50%;
                    transform: translateX(-50%);
                    width: rem(82px);
                    height: rem(50px);
                    pointer-events: none;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    background-image: url('/css/assets/360spin.svg');
                }
            }
        }
    }

    &.isActive, &.is-open {
        .module-header .module-title,
        .module-next,
        .module-tabs-nav .item,
        .module-tab.isActive a img,
        .module-tab.isActive .tab-title, .module-tab.isActive .tab-text,
        .module-stat .module-stat-title, .module-stat .module-stat-text, .module-stat .module-stat-icon, .module-stat .module-stat-image,
        .slider2.isInitialized .slick-slide .item.image-loaded,
        .slider2-thumb.isInitialized .slick-slide .item.image-loaded,
        .noSlider3 .item > *,
        .module-accordion .card,
        .module-products .item, .module-products .item .title, .module-products .item .text, .module-products .item .more-info,
        .module-documents .module-documents-item .icon, .module-documents .module-documents-item .title, .module-documents .module-documents-item .text,
        .images-grid .image-grid-item img {
            opacity: 1;
            transform: translate(0, 0);
        }

        .module-header {
            &:before {
                width: rem(410px);
                transition: width $t-fast $easeOutCubic 0.6s;
            }

            .module-title {
                transition: opacity $t-fast linear 0.2s, transform $t-normal $easeOutCubic 0.2s;
            }
        }

        .module-next {
            transition: opacity $t-fast linear 0.8s, transform $t-normal $easeOutCubic 0.8s;
        }

        .module-tabs-nav {
            .item {
                transition: opacity $t-fast linear 1.4s, transform $t-normal $easeOutCubic 1.4s, color $t-fast linear 0s, background-color $t-fast linear 0s;
                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), color $t-fast linear 0s, background-color $t-fast linear 0s;
                    }
                }
            }
        }

        .module-tab {
            &.isActive {
                a {
                    img {
                        transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                    }

                    $delay: 0.1s;
                    @for $i from 1 through 10 {
                        &:nth-child(#{$i}) img {
                            transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                        }
                    }
                }

                .tab-title {
                    transition: opacity $t-fast linear 0.6s, transform $t-normal $easeOutCubic 0.6s;
                }

                .tab-text {
                    transition: opacity $t-fast linear 0.7s, transform $t-normal $easeOutCubic 0.7s;
                }
            }
        }

        .module-stat {
            .module-stat-image {
                transition: opacity $t-fast $easeOutCubic 1.3s, transform $t-normal $easeOutCubic 1.3s;
            }

            .module-stat-title {
                transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
            }

            .module-stat-text {
                transition: opacity $t-fast $easeOutCubic 1.5s, transform $t-normal $easeOutCubic 1.5s;
            }

            .module-stat-icon {
                transition: opacity $t-fast $easeOutCubic 1.6s, transform $t-normal $easeOutCubic 1.6s;
            }

            $delay: 0.1s;
            @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                    .module-stat-image {
                        transition: opacity $t-fast $easeOutCubic calc(0.3s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.3s + (#{$i} * #{$delay}));
                    }

                    .module-stat-title {
                        transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                    }

                    .module-stat-text {
                        transition: opacity $t-fast $easeOutCubic calc(0.5s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.5s + (#{$i} * #{$delay}));
                    }

                    .module-stat-icon {
                        transition: opacity $t-fast $easeOutCubic calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
                    }
                }
            }
        }

        .slider2 {
            .slick-slide {
                .item {
                    transition: opacity $t-fast $easeOutCubic 0.4s, transform $t-normal $easeOutCubic 0.4s;
                }
            }
        }

        .slider2-thumb {
            .slick-slide {
                .item {
                    transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                }

                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) .item {
                        transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                    }
                }
            }
        }

        .noSlider3 {
            .item {
                > * {
                    transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                }

                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) > * {
                        transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                    }
                }
            }
        }

        .module-accordion {
            .card {
                transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                    }
                }
            }
        }

        .module-products {
            .col-12.col-md-6 {
                .item {
                    transition: opacity $t-fast $easeOutCubic 1.2s, transform $t-normal $easeOutCubic 1.2s, background-color $t-fast linear 0s;

                    .title {
                        transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                    }

                    .text {
                        transition: opacity $t-fast $easeOutCubic 1.5s, transform $t-normal $easeOutCubic 1.5s;
                    }

                    .more-info {
                        transition: opacity $t-fast $easeOutCubic 1.6s, transform $t-normal $easeOutCubic 1.6s;
                    }
                }

                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) .item {
                        transition: opacity $t-fast $easeOutCubic calc(0.2s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.2s + (#{$i} * #{$delay})), background-color $t-fast linear 0s;

                        .title {
                            transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                        }

                        .text {
                            transition: opacity $t-fast $easeOutCubic calc(0.5s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.5s + (#{$i} * #{$delay}));
                        }

                        .more-info {
                            transition: opacity $t-fast $easeOutCubic calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
                        }
                    }
                }
            }
        }

        .module-documents {
            .col-12.col-md-5 {
                .module-documents-item {
                    .icon {
                        transition: opacity $t-fast $easeOutCubic 1.4s, transform $t-normal $easeOutCubic 1.4s;
                    }

                    .title {
                        transition: opacity $t-fast $easeOutCubic 1.5s, transform $t-normal $easeOutCubic 1.5s;
                    }

                    .text {
                        transition: opacity $t-fast $easeOutCubic 1.6s, transform $t-normal $easeOutCubic 1.6s;
                    }
                }

                $delay: 0.1s;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) .module-documents-item {
                        .icon {
                            transition: opacity $t-fast $easeOutCubic calc(0.4s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.4s + (#{$i} * #{$delay}));
                        }

                        .title {
                            transition: opacity $t-fast $easeOutCubic calc(0.5s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.5s + (#{$i} * #{$delay}));
                        }

                        .text {
                            transition: opacity $t-fast $easeOutCubic calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
                        }
                    }
                }
            }
        }

        .images-grid {
            .image-grid-item {
                img {
                    transition: opacity $t-fast $easeOutCubic 1.2s, transform $t-normal $easeOutCubic 1.2s;
                }

                $delay: 0.1s;
                @for $i from 1 through 6 {
                    &:nth-child(#{$i}) img {
                        transition: opacity $t-fast $easeOutCubic calc(0.6s + (#{$i} * #{$delay})), transform $t-normal $easeOutCubic calc(0.6s + (#{$i} * #{$delay}));
                    }
                }
            }
        }
    }


    @include media-breakpoint-down(sm) {
        display: block !important;
        padding-left: 0;
        padding-right: 0;

        .presentation-container {
            padding-bottom: rem(130px) !important;

            .presentation-price-container {
                position: absolute;
                bottom: 0;
                left: 0;
                padding-left: rem(20px);
                padding-right: rem(20px);
                height: rem(105px);
                width: 100%;
                display: flex;
                align-items: center;


                &.presentation-price-container-gb-region-selector {
                    height: auto;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    height: 1px;
                    width: calc(100% - #{rem(40px)});
                    background-color: $c-gray2;
                }

                .module-text {
                    display: flex;
                    align-items: flex-end;
                    width: 100%;

                    strong {
                        line-height: 1;
                    }

                    > *:last-child {
                        margin-left: rem(20px);
                    }
                }
            }
        }

        .module-body {
            display: none;
            padding-left: rem(20px);
            padding-right: rem(20px);

            .module-wrap {
                padding-bottom: rem(20px);
                padding-top: rem(20px);

            }

            overflow: hidden;
            transition: max-height 0.4s ease-in-out;
        }

        .module-image {
            margin: 0 !important;
            width: 100%;

            img {
                width: 100%;
            }
        }

        &:nth-child(2n) .module-header {
            background-color: rgba($gray, 1);
        }

        &:nth-child(2n + 1) .module-header {
            background-color: rgba($gray, 0.5);
        }

        .module-header {
            min-height: rem(75px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 rem(20px);

            .module-title {
                font-family: Montserrat;
                font-weight: bold;
                font-size: rem(17px);
                color: $gray-dark;
                text-decoration: none;
                margin: 0;
            }

            &:after {
                content: url(/img/accordion/m-down.png);
                position: absolute;
                right: rem(45px);

                transform: rotate(0);
                display: block;
            }
        }

        .module-video {
            max-width: 100%;

            iframe {
                max-width: 100% !important;
            }
        }

        &.is-open {
            .module-header {
                background-color: white;

                &:before {                  
                    left: rem(22px);
                    right: rem(22px);
                    height: 1px;
                    border-bottom-width: 1px;
                }

                &:after {
                    transform: rotate(180deg);
                }


                .module-title {
                    color: $fl-rose;
                    text-decoration: none;
                }
            }

            .module-body {
                pointer-events: all;
            }
        }

        .module-next {
            display: none;
        }

        .module-video {
            margin-bottom: rem(20px);
        }

        .module-tabs {
            overflow: hidden;
            max-width: calc(100% + (#{rem(20px)} * 2));
            width: calc(100% + (#{rem(20px)} * 2));
            margin-left: rem(-20px);
            padding: 0 rem(20px);

            .tab-title,
            .tab-text {
                padding-left: 0px;
            }

            .tab-title {
                font-size: $f-size-small;
                font-family: Arvo, Arial, Tahoma;
                font-weight: bold;
                color: $c-fama;
                text-transform: uppercase;
                margin-bottom: rem(22px);
                line-height: 1.2;
            }
        }

        .module-tabs-nav {
            display: flex;
            align-items: center;
            max-width: 100vw;
            width: 100vw;
            margin: 0;
            margin-left: rem(-20px);
            padding: 0 rem(20px);
            flex-wrap: nowrap;
            overflow-x: auto;

            > .item {
                background-color: rgba($gray-dark, 0.1);

                border-radius: rem(11px);
                padding: rem(5px) rem(10px);
                margin-right: rem(23px);

                margin-bottom: rem(20px);

                font-size: $f-size-small;
                font-weight: 800;
                color: rgba($gray-dark, 0.5);

                text-transform: uppercase;

                cursor: pointer;

                transition: all ease-in-out $t-fast;

                &:hover {
                    color: $gray-dark;
                    background-color: rgba($gray-dark, 0.3);
                }

                &.is-active,
                &.is-active:hover {
                    background-color: $gray-dark;
                    color: $white;
                }
            }
        }

        .special-row {
            margin-left: rem(-152px);

            .autoimg {
                max-width: 100%;
                margin-bottom: rem(48px);
                margin-right: rem(-100px);
            }
        }

        .special-image {
            width: 100%;
            margin-bottom: rem(12px);

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .eins {
            text-align: center;
            padding-top: rem(40px);
            padding-bottom: rem(50px);
        }

        &#fotos-de-clientes {
            .button-dark {
                margin-bottom: rem(40px);
                display: inline-block;
            }
        }

        .module-gallery {
            margin-top: rem(25px);

            .slider2 {
                height: 40vh;

                .slick-list, .slick-track, .slick-slide > div, .item {
                    height: 100%;
                }

                .slick-prev, .slick-next {
                    top: unset;
                    bottom: 100%;
                    left: unset;
                    right: unset;
                    height: rem(30px);
                    width: rem(30px);
                    transform: none;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: rem(15px);
                        height: rem(20px);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }

                .slick-prev {
                    right: rem(30px);

                    &:before {
                        background-image: url('/css/assets/slider2-left.svg');
                    }
                }

                .slick-next {
                    right: 0;

                    &:before {
                        background-image: url('/css/assets/slider2-right.svg');
                    }
                }
            }

            .noSlider3 {
                display: flex;
                overflow: auto;
                width: 100vw;
                //margin-left: rem(-20px);

                .item {
                    margin-right: rem(15px);

                    > * {
                        height: rem(150px);
                    }

                    > iframe {
                        width: rem(250px);
                    }
                }
            }

            .gallery-info,
            .gallery-title {
                color: $c-famaliving;
                font-size: $f-size-small;
                font-weight: 500;
                margin-bottom: rem(5px);
            }

            .gallery-info {
                margin-right: rem(25px);
                display: block;
            }

            .control-fullscreen-clientphotos {
                display: none;
            }
        }

        .module-stat {
            position: relative;
            margin-bottom: rem(25px);
            float: left;
            width: 100%;

            &-image {
                position: absolute;
                left: 0;
                top: 0;
                float: left;
                margin-right: rem(15px);
                max-width: rem(85px);
                height: rem(75px);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: left top;
                }

                & + .module-stat-title, & + .module-stat-title + .module-stat-text {
                    padding-left: rem(90px);
                }
            }

            &-icon {
                margin-bottom: rem(7px);
                opacity: 0;
                transform: translateY(5rem);
                height: rem(36px);

                img {
                    height: 100%;
                    max-width: 100%;
                    object-position: left bottom;
                    object-fit: contain;
                }
            }

            &-title {
                font-size: $f-size-small;
                font-weight: bold;
                margin-bottom: rem(8px);
                text-transform: uppercase;
            }
        }

        .module-text-subtitle {
            font-size: $f-size-small;
            font-weight: bold;
            margin-bottom: rem(8px);
            text-transform: uppercase;
        }

        .iframe-360-container {
            position: relative;
            margin-left: rem(-20px);
            width: 100vw !important;
            padding-top: 60%;

            .iframe-360 {
                position: absolute;
                top: 0;
                left: 0;
                height: 100% !important;
                width: 100% !important;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .presentation-container {
            > .module-row {
                *:first-child {
                    flex-basis: 0 !important;
                    flex-grow: 1 !important;
                }

                .presentation-price-container {
                    width: rem(200px);
                    min-width: rem(200px);
                    flex-grow: 0 !important;

                    * {
                        line-height: 1.5 !important;
                    }
                }
            }
        }

        .module-wrap {
            padding-left: rem(98px);
            padding-right: rem(20px);
            position: relative;
            z-index: 2;
        }

        .iframe-360 {
            border: 5px solid transparent !important;
            outline: 1px solid $c-gray2;
            margin-left: calc(-#{rem(96px)} + #{rem(25px)});
            height: rem(470px);
            margin-top: rem(50px);
            background-color: #fff;
            width: calc(100vw - #{rem(385px)} - #{$lateral-space} - #{rem(50px)}) !important;
            @media (min-width: $special-container-max-width) {
                width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - #{rem(385px)} - #{$lateral-space});
            }
        }

        .module-header {
            position: relative;

            &:before {
                width: 0;
                height: 1px;
                border-bottom-width: 1px;
            }

            .module-title {
                font-family: Arvo, Arial, Tahoma;
                font-size: $f-size-title;
                padding-top: rem(71px);
                padding-bottom: rem(23px);
                color: $c-fama;
                opacity: 0;
                transform: translateY(5rem);
            }
        }

        .module-body {
            padding-top: rem(25px);
        }

        .module-text, .tab-text {
            margin-bottom: rem(22px);
            line-height: 1.5;

            * {
                line-height: 1.5;
            }
        }

        .module-video {
            margin-left: rem(-1.5px);
        }

        .module-row {
            margin-bottom: rem(22px);
        }

        .module-next {
            display: flex;
            margin-top: rem(80px);
            margin-bottom: rem(55px);
            justify-content: flex-end;
            align-items: center;
            opacity: 0;
            transform: translateY(5rem);

            a {

                font-weight: bold;
                font-size: $f-size-small;
                color: $gray-dark;
                transition: color $t-fast ease-in-out;
                text-transform: uppercase;
                display: flex;
                align-items: center;

                &:after {
                    content: '';
                    width: rem(17px);
                    height: rem(11px);
                    margin-left: rem(25px);
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    background-image: url('/css/assets/right-arrow.png');
                }

                &:hover {
                    color: $c-fama;
                }
            }
        }

        .module-tabs-nav {
            display: flex;
            align-items: center;

            > .item {
                background-color: rgba($gray-dark, 0.1);

                border-radius: rem(11px);
                padding: rem(5px) rem(10px);
                margin-right: rem(23px);

                margin-bottom: rem(22px);

                font-size: $f-size-small;
                font-weight: 800;
                color: rgba($gray-dark, 0.5);

                text-transform: uppercase;

                cursor: pointer;
                white-space: nowrap;
                opacity: 0;
                transform: translateX(5rem);
                transition: all $t-fast linear;

                &:hover {
                    color: $gray-dark;
                    background-color: rgba($gray-dark, 0.3);
                }

                &.is-active,
                &.is-active:hover {
                    background-color: $gray-dark;
                    color: $white;
                }
            }
        }

        .module-tabs {
            .tab-title,
            .tab-text {
                padding-left: rem(40px);
                opacity: 0;
                transform: translateY(5rem);
            }

            .tab-title {
                font-size: $f-size-small;
                font-family: Arvo, Arial, Tahoma;
                font-weight: bold;
                color: $c-fama;
                text-transform: uppercase;
                margin-bottom: rem(22px);
            }

            .module-tab {
                padding-top: rem(40px);

                a {
                    img {
                        opacity: 0;
                        transform: translateY(5rem);
                    }
                }
            }
        }

        .special-row {
            margin-left: rem(-152px);

            .autoimg {
                margin-bottom: rem(48px);
            }
        }

        .special-image {
            width: rem(227px);
            margin-bottom: rem(12px);

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .module-text-image {
            margin-top: rem(30px);
            margin-bottom: rem(70px);
        }

        .module-gallery {
            .gallery-info,
            .gallery-title {
                color: $c-famaliving;
                font-size: $f-size-small;
                font-weight: 500;
                margin-bottom: 5px;
            }

            .gallery-info {
                margin-right: rem(25px);
            }

            .slider2,
            .slider2-thumb {
                margin-left: rem(-42px);
                width: calc(100% + #{rem(42px)});
            }

            .slider2 {
                .item {
                    opacity: 0;
                    transform: translateY(5rem);
                }
            }

            .slider2-thumb {
                .item {
                    opacity: 0;
                    transform: translateX(5rem);
                }
            }

            .slider2-container {
                position: relative;

                .btn-control {
                    position: absolute;
                    top: rem(25px);
                    right: rem(25px);
                    z-index: 2;
                    cursor: pointer;
                }
            }

            .slider2 {
                .slick-prev, .slick-next {
                    height: 100%;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: rem(15px);
                        height: rem(15px);
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                    }
                }

                .slick-prev:before {
                    background-image: url('/css/assets/slider2-left.svg');
                }

                .slick-next:before {
                    background-image: url('/css/assets/slider2-right.svg');
                }
            }
        }

        .noSlider3 {
            display: flex;
            overflow: auto;
            margin-left: rem(-96px);
            width: calc(100vw - #{rem(385px)} - #{$lateral-space});
            @media (min-width: $special-container-max-width) {
                width: calc(100vw - #{$lateral-space} - ((100vw - #{$special-container-max-width}) / 2) - #{rem(390px)});
            }

            .item {
                margin-right: rem(15px);

                > * {
                    opacity: 0;
                    transform: translateX(5rem);
                    height: rem(285px);
                    margin-right: rem(15px);
                }
                
                .wrap-info{
                    height: auto !important;
                    min-height: 10rem;
                    max-height: 28.5rem;
                    margin: 1rem 0;
                    line-height: 1.4rem;

                  
                }
             
                
                > iframe {
                    width: rem(500px);
                }

            }
            a.item {
                color: #3a80be;
                position: relative;
            }
            
            a.item:hover {
                color: #285983;
            }

         
        }

        .module-stat {
            margin-bottom: rem(25px);
            float: left;
            width: 100%;

            &-image {
                float: left;
                margin-right: rem(15px);
                opacity: 0;
                transform: translateY(5rem);

                img {
                    height: 100%;
                }
            }

            &-icon {
                margin-bottom: rem(7px);
                opacity: 0;
                transform: translateY(5rem);
                height: rem(36px);

                img {
                    height: 100%;
                    max-width: 100%;
                    object-position: left bottom;
                    object-fit: contain;
                }
            }

            &-title {
                font-size: $f-size-small;
                font-weight: bold;
                margin-bottom: rem(8px);
                text-transform: uppercase;
                opacity: 0;
                transform: translateY(5rem);
            }

            &-text {
                opacity: 0;
                transform: translateY(5rem);
                line-height: 1.33;
            }
        }

        .module-text-subtitle {
            font-size: $f-size-small;
            font-weight: bold;
            margin-bottom: rem(8px);
            text-transform: uppercase;
        }
    }
    @media (min-width: $special-container-max-width) {
        .iframe-360 {
            width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - #{rem(385px)} - #{$lateral-space} - #{rem(50px)}) !important;
        }
    }

    @media (max-width: 768px) {
        .module-gallery .noSlider3 .item .wrap-info.md-visible{
            display: none;
        }
    }

    @media (min-width: 769px) {
        .module-gallery .noSlider3 .item {
            position: relative;
        }
        .module-gallery .noSlider3 .item .wrap-info.md-visible{
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            background-color: rgba(0,0,0,.5);
            bottom: 0;
            color: #fff;
            font-size: 1.3rem;
            height: -moz-fit-content!important;
            height: fit-content!important;
            margin: 0;
            max-width: 70%;
            padding: 1rem;
            position: absolute;
        }
    }


}

.images-grid {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    flex-grow: 1;
    width: calc(100% + #{rem(130px)});
    //margin-left: rem(-115px);
    margin-left: 0;

    .images-grid-container {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
    }

    .image-grid-item {
        position: relative;
        height: rem(115px);
        width: calc((100% / 4) - #{rem(15px)});
        min-width: calc((100% / 4) - #{rem(15px)});
        margin-right: rem(15px);
        margin-bottom: rem(15px);
        @include media-breakpoint-up(sm) {
            &:nth-child(5n+2),
            &:nth-child(5n+4) {

            }
            &:nth-child(5n+3),
            &:nth-child(5n) {
                margin-top: rem(130px);
                margin-left: calc((100% / 4) * -1);
            }
            &:nth-child(5n+1) {
                height: rem(245px);
                margin-top: 0;
                margin-left: 0;
            }
        }

        img {
            position: relative;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: center;
            opacity: 0;
            transform: translateX(5rem);
        }
    }

    @include media-breakpoint-down(sm) {
        width: calc(100% + #{rem(40px)} + #{rem(15px)});
        margin-left: rem(-20px);
        .image-grid-item {
            width: calc((100% / 3) - #{rem(15px)});
            min-width: calc((100% / 3) - #{rem(15px)});

            &:nth-child(3n) {
                margin-top: rem(130px);
                margin-left: calc((100% / 3) * -1);
            }

            &:nth-child(3n+1) {
                height: rem(245px);
                margin-top: 0;
                margin-left: 0;
            }
        }
    }
}

.module-documents {
    .row {
        flex-wrap: wrap;
    }
}

.module-documents-item {
    display: flex;
    align-items: flex-start;
    color: $gray-dark;
    margin-bottom: rem(58px);

    .icon, .title, .text {
        opacity: 0;
        transform: translateY(5rem);
    }

    .icon {
        margin-right: rem(10px);
    }

    .title {
        font-size: $f-size-normal-body;
        font-weight: 500;  
        text-decoration: underline;
        margin-bottom: rem(10px);
        margin-top: rem(7px);
    }

    .text {
        font-size: $f-size-very-small;
        font-weight: 500;
        line-height: 1.18;
        line-height: normal;
    }
}

.module-accordion {
    .card {
        border: none;
        border-bottom: 1px solid $c-gray2;
        opacity: 0;
        transform: translateY(5rem);

        &:last-child {
            border-bottom: 0;
        }
    }

    .card-header {
        font-size: $f-size-medium;
        font-weight: bold;
        padding: rem(40px) rem(55px) rem(34px) rem(90px);
        cursor: pointer;
        position: relative;
        border: 0;

        &:before {
            content: url('/img/accordion/help.png');
            position: absolute;
            left: rem(32px);
            top: 50%;
            transform: translateY(-50%);
            display: block;
        }

        &:after {
            content: url('/img/accordion/plus.png');
            display: block;
            padding: 0 5px;
            border-radius: 100%;
            border: 1px solid $gray-dark;
            position: absolute;
            right: rem(32px);
            top: 50%;
            transform-origin: center top;
            transform: rotate(45deg) translateY(-50%);
            transition: transform $t-normal $easeOutCubic;
        }

        &.collapsed:after {
            transform: rotate(360deg) translateY(-50%);
        }

        & + .collapse {
            &.show {
                .card-body {
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity $t-fast linear 0.4s, transform $t-normal linear 0.4s;
                }
            }

            .card-body {
                opacity: 0;
                transform: translateY(5rem);
                transition: opacity $t-fast linear 0s, transform 0s linear 0.4s;
            }
        }
    }

    .card-body {
        padding: 0 rem(55px) rem(40px) rem(90px);
        position: relative;
        font-weight: 500;

        &:before {
            content: url('/img/accordion/help-1.png');
            position: absolute;
            left: rem(32px);
            top: 0;

            display: block;
        }
    }

    @include media-breakpoint-down(sm) {
        .card-header {
            padding-left: rem(32px);
            padding-right: rem(32px);
            font-size: rem(17px);
            line-height: 1.3;

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
                line-height: 1;
                font-size: $f-size-medium;
            }
        }
        .card-body {
            padding-left: rem(32px);
            padding-right: 0;
            line-height: 1.2;

            &:before {
                left: 0;
            }

        }
    }

    @include media-breakpoint-up(md) {
        margin-left: rem(-90px);
        margin-right: rem(-20px);
    }
}

.module-products {
    .item {
        opacity: 0;
        transform: translateY(5rem);

        .title, .text, .more-info {
            opacity: 0;
            transform: translateY(5rem);
        }

        &:hover {
            background-color: rgba($gray-dark, 0.1);
        }
    }

    &.mode-combination {
        width: calc(100% + #{rem(45px)} + #{rem(10px)});
        margin-left: calc((#{rem(45px)} + #{rem(5px)}) / 2 * -1);

        > .row {
            flex-wrap: wrap;

            > * {
                margin-bottom: rem(45px);
            }
        }

        .item {
            height: 100%;
            padding: rem(10px);           
            color: $gray-dark;
            transition: background-color $t-fast ease-in;
            display: block;

            .image {
                height: rem(201px);
                margin-bottom: rem(9px);
            }

            .image img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .title {
                font-size: rem(21px);
                margin-bottom: rem(10px);
                opacity: 0;
                transform: translateY(5rem);
            }

            .text {
                font-size: $f-size-small;
                font-family: Arvo, Arial, Tahoma;
                opacity: 0;
                transform: translateY(5rem);
            }
        }
    }

    &.mode-related {
        margin: rem(52px) auto 0 auto;
        position: relative;
        display: inline-block;
        left: 50%;
        max-width: 100vw;
        overflow: auto;
        //overflow-y: hidden;
        transform: translateX(-50%);

        .module-products-container {
            white-space: nowrap;
            text-align: center;
            display: inline-flex;
            overflow-y: hidden;

            // Fix problem with scrollbars in desktop chrome.
            //overflow: hidden;
            @include media-breakpoint-down(sm) {
                overflow-x: auto;
            }
        
            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }
            
            &::-webkit-scrollbar:horizontal {
                height: 4px;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                border: 1px solid #c70087;
                background-color: #c70087;
            }
            
            &::-webkit-scrollbar-track { 
                background-color: rgba(#c70087, 0.32); 
                border-radius: 8px; 
            }
            &::-webkit-scrollbar-track-piece  {
                background-color: rgba(#c70087, 0.32); 
                border-radius: 8px; 
            }
            
            &::-webkit-scrollbar-thumb:vertical {
                border-radius: 8px;
                border: 1px solid #c70087;
                background-color: #c70087;
            }
            /* Thin Scrollbar for firefox */
            &{
                scrollbar-color: #c70087 rgba(#c70087, 0.32);
                scrollbar-width: thin;                
            }

            .item-wrapper {
                //width: rem(360px);
                //min-width: rem(360px);
                width: calc(100% / 3);
                min-width: calc(100% / 3);
                max-width: 36rem;
                display: inline-block;
                white-space: normal;
                text-align: left;
                margin: 0 0 rem(40px);
            }
        }

        .item {
            height: 100%;
            padding: calc(#{rem(10px)} + #{rem(257px)} + #{rem(9px)}) rem(20px) rem(25px) rem(20px);
            color: $gray-dark;
            transition: background-color $t-fast ease-in;
            display: flex;
            flex-direction: column;
            transform: translate(5rem, 0);

            &.isVisible {
                opacity: 1;
                transform: translate(0, 0) !important;
                transition: opacity $t-fast linear 0.2s, transform $t-normal $easeOutCubic 0.2s, background-color $t-fast linear 0s;

                .title, .text, .more-info {
                    opacity: 1;
                    transform: translate(0, 0) !important;
                }

                .title {
                    transition: opacity $t-fast linear 0.4s, transform $t-normal $easeOutCubic 0.4s;
                }

                .text {
                    transition: opacity $t-fast linear 0.5s, transform $t-normal $easeOutCubic 0.5s;
                }

                .more-info {
                    transition: opacity $t-fast linear 0.6s, transform $t-normal $easeOutCubic 0.6s;
                }
            }

            .image {
                height: rem(257px);
                width: calc(100% - #{rem(40px)});
                position: absolute;
                top: rem(10px);
                left: rem(20px);
            }

            .image img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .title {
                font-size: rem(21px);
                margin-bottom: rem(10px);
            }

            .text {
                font-size: $f-size-small;
                font-family: Arvo, Arial, Tahoma;
                margin-bottom: rem(23px);
            }

            .more-info {
                font-size: $f-size-small;
                font-weight: bold;
                height: rem(15px);
                width: calc(100% - #{rem(40px)});
                position: absolute;
                bottom: rem(10px);
                left: rem(20px);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &.mode-combination {
            width: 100%;
            margin-left: 0;

            > .row {
                flex-wrap: nowrap;
                overflow-x: auto;
                width: 100vw;
                margin-left: rem(-20px) !important;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 7px;
                }
                
                &::-webkit-scrollbar:horizontal {
                    height: 4px;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    border: 1px solid #c70087;
                    background-color: #c70087;
                }
                
                &::-webkit-scrollbar-track { 
                    background-color: rgba(#c70087, 0.32); 
                    border-radius: 8px; 
                }
                &::-webkit-scrollbar-track-piece  {
                    background-color: rgba(#c70087, 0.32); 
                    border-radius: 8px; 
                }
                
                &::-webkit-scrollbar-thumb:vertical {
                    border-radius: 8px;
                    border: 1px solid #c70087;
                    background-color: #c70087;
                }
                /* Thin Scrollbar for firefox */
                &{
                    scrollbar-color: #c70087 rgba(#c70087, 0.32);
                    scrollbar-width: thin;                
                }



                > * {
                    max-width: 60%;
                }
            }
        }
        &.mode-related {
            margin-top: rem(30px);
            overflow: visible;

            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                left: 50%;
                bottom: 0;
                width: 100vw;
                height: calc(100% + #{rem(110px)});
                transform: translateX(-50%);
                background-color: $gray;
                pointer-events: none;
            }

            .module-products-container {
                display: flex;

                .item-wrapper {
                    width: rem(275px);
                    min-width: rem(275px);
                    max-width: 75%;

                    .item {                     
                        position: relative;
                        padding-bottom: rem(20px);

                        .image {
                            height: rem(200px);
                        }

                        .more-info {
                            position: absolute;
                            bottom: rem(10px);
                            left: rem(20px);
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 500px) {
        &.mode-combination {
            > .row {
                flex-wrap: nowrap;
                overflow-x: auto;
                width: 100vw;
                margin-left: rem(-20px) !important;

                > * {
                    max-width: 85%;
                }
            }
        }
    }
}

.module-valoraciones {
    .info {
        font-size: $f-size-small;
        font-weight: 500;
        white-space: nowrap;
    }

    .module-header {
        margin-bottom: rem(21px);

        .info {
            margin-left: rem(25px);
        }
    }

    .module-body {
        .lateral {           
            width: rem(375px);
            border-right: 1px solid $gray;

            .lateral-header .info {
                opacity: 0.5;
            }

            .lateral-header {
                margin-bottom: rem(36px);
                padding-bottom: rem(35px);
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    width: rem(80px);
                    left: 0;
                    height: rem(5px);
                    background-color: $c-fama;
                }
            }

            .lateral-body {
                width: rem(286px);
            }

            .th,
            .tr {
                display: flex;              
                align-items: center;
                padding: rem(18px) 0 rem(19px);
                position: relative;

                > .td:first-child {
                    flex: 0 0 rem(78px);
                    width: rem(78px);
                    margin-right: rem(43px);
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    width: 80%;
                    background-color: $gray;
                }
            }

            .th {
                font-size: $f-size-very-small;
                font-weight: bold;
                text-transform: uppercase;
            }

            .tr {
                cursor: pointer;

                &.isSelected {
                    &:before {
                        background-color: $gray;
                    }
                }

                &.empty {
                    pointer-events: none;

                    &:before {
                        display: none;
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    right: 0;
                    top: 0;
                    pointer-events: none;
                    height: 100%;
                    width: 100vw;
                    transition: background-color $t-fast linear;
                }

                .td {
                    display: flex;
                    align-items: center;
                }

                &:hover {
                    &:before {
                        background-color: $gray;
                    }
                }
            }

            .progress {
                width: rem(100px);
                min-width: rem(100px);
                max-width: rem(100px);
                margin-right: rem(20px);
                height: rem(5px);
            }
        }

        .tr .list-rates {
            flex: 1;
        }

        .rate {
            padding-top: rem(30px);
            padding-bottom: rem(23px);
            border-bottom: 1px solid $gray;

            &:last-child {
                border: none;
            }

            .rate-header {
                display: flex;
                margin-bottom: rem(20px);

                .photo {
                    width: rem(44px);
                    min-width: rem(44px);
                    height: rem(44px);
                    border-radius: 100%;
                    overflow: hidden;
                    margin-right: rem(25px);
                    font-size: rem(21px);
                    font-weight: 300;
                    color: white;
                    background-color: $c-fama;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .rate-order-verified {
                    font-weight: bold;
                    font-size: $f-size-very-small;
                    color: $fl-rose;
                    margin-left: rem(25px);
                }

                .name {
                    font-weight: 500;
                    margin-bottom: rem(7px);
                }

                .stars {
                    margin-bottom: rem(16px);
                }

                .date {
                    font-weight: 500;
                    font-size: $f-size-small;
                    opacity: 0.5;
                }
            }

            .rate-comment {
                font-size: $f-size-small;
                font-weight: 500;
                line-height: 1.54;
            }
        }
    }

    .desktop-valoration-opinions {
        .list-rates {
            position: relative;
            display: none !important;
            min-height: 382px;      
            overflow: hidden;

            &.seeMore {
                max-height: unset;

                .list-rates-see-more {
                    display: none;
                }
            }

            &.isVisible {
                display: block !important;
            }

            .list-rates-see-more {
                position: absolute;
                z-index: 1;
                width: 100%;
                bottom: 0;
                left: 0;
                height: rem(50px);
                background: rgba(255, 255, 255, 0);
                background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 63%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(63%, rgba(255, 255, 255, 1)));
                background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 63%);
                background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 63%);
                background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 63%);
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 63%);

                .text-see-more {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    color: $pink;
                    font-size: rem(15px);
                    font-weight: 500;
                    justify-self: flex-start;
                    text-decoration: underline;
                    cursor: pointer;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: rem(50px) !important;
        padding: 0 rem(10px);
        .module-body .container {
            padding-left: 0;
            padding-right: 0;
        }

        .module-header {
            .module-title {
                font-size: rem(17px);
            }

            &:after {
                left: rem(20px);
            }

            .container {
                padding: 0;
            }
        }

        .module-body .lateral {
            width: 100%;
            border: none;

            .lateral-header {
                padding-left: 0;
                padding-right: 0;
            }

            .lateral-body {
                width: 100%;

                .tr,
                .th {
                    padding-left: rem(20px);
                    padding-right: rem(20px);
                }
            }


            .list-rates {
                padding-left: rem(20px);
                padding-right: rem(20px);
            }

            .tr,
            .th {
                position: relative;

                &:after {
                    left: rem(-20px);
                    width: 100vw;
                }
            }

            .tr {
                position: relative;

                &:before {
                    content: url('/img/accordion/m-down.png');
                    position: absolute;
                    right: rem(-20px);
                    top: 50%;
                    width: rem(12px);
                    height: rem(12px);
                    transform: translateY(-50%) rotate(270deg);
                    display: block;
                    background-color: transparent !important;
                }

                &:not(.collapsed) {
                    position: fixed;
                    z-index: 1001;
                    left: 0;
                    top: 0;
                    width: 100vw;
                    height: 50px;
                    background-color: #fff;
                    padding: 0 rem(20px);

                    &:after {
                        left: rem(10px);
                        width: calc(100vw - #{rem(20px)});
                    }

                    &:before {
                        right: rem(20px);
                        content: url('/css/assets/close.svg');
                    }
                }
            }

            .list-rates {
                position: relative;
                padding: 0;
                bottom: 50vh;
                transition: bottom $t-normal $easeOutCubic 0s, height 0s linear 0s;

                .rate .rate-header .rate-order-verified {
                    display: block;
                    line-height: 2;
                    margin-left: 0;
                }

                &.collapsing {
                    transition: bottom $t-normal $easeOutCubic 0s, height 0s linear 0s;
                }

                &.show, &.collapsing {
                    position: fixed;
                    z-index: 1000;
                    left: 0;
                    bottom: 0;
                    height: calc(100vh - 50px);
                    width: 100%;
                    overflow: auto;
                    background-color: #fff;
                    padding: 0 rem(20px) !important;
                }
            }
        }
        .desktop-valoration-opinions {
            display: none !important;
        }
    }
}

.module-related-products {
    @include media-breakpoint-down(sm) {
        .module-header {
            padding-left: rem(10px);
            position: relative;
            z-index: 1;
        }
    }
}

.module-form {
    .row {
        flex-wrap: wrap;
    }

    .module-header {
        margin-bottom: rem(49px);
    }

    .module-body {
        padding-bottom: rem(25px);

        /*
        @include media-breakpoint-up(md) {
            padding-left: rem(97px);
            padding-right: rem(86px);
        }
        */
        

        .container {
            border-bottom: 1px solid $gray;
        }

        .form-contact-product{
            .form-group{
                label {
                    white-space: normal;
                    pointer-events: auto;
                }
            }
        }
       

        label {
            font-size: $f-size-small;
            font-weight: 500;
            color: rgba($gray-dark, 1);
            margin-bottom: 3rem;
            padding: 0;
        }

        .form-check {
            position: relative;
            padding-left: 0;

            input {
                visibility: hidden;
                position: absolute;
            }

            label {
                margin-bottom: 0;
                position: absolute;
                padding-left: rem(28px);
                cursor: pointer;

                &:before {
                    content: '';
                    border: 1px solid rgba(#c70087, 0.35);
                    height: 20px;
                    width: rem(20px);
                    position: absolute;
                    left: 0;
                    display: block;
                    top: 50%;
                    transform: translateY(-50%);;
                }

                &:after {
                    content: '';
                    border: 0 solid $gray-dark;
                    position: absolute;
                    left: rem(5px);
                    top: 50%;
                    transform: translateY(-50%);
                    transition: border-width $t-fast ease-in-out;
                }
            }

            input:checked {
                & + label:after {
                    border-width: rem(5px);
                }
            }
        }

        input[type=text],
        input[type=email],
        input[type=number],
        textarea {
            background-color: rgba($gray-dark, 0.05);
            border: transparent 1px solid;
            border-bottom: 1px solid rgba($gray-dark, 0.35);
            padding: rem(14px) rem(10px);
            line-height: 1;
            color: $gray-dark;
            font-size: $f-size-small;
            height: rem(30px);
        }

        select {

            line-height: 1;
            color: $gray-dark;
            font-size: $f-size-small;
            border: transparent 1px solid;
            background-color: rgba($gray-dark, 0.05);
            border-bottom: 1px solid rgba($gray-dark, 0.35);
            height: rem(30px);
            padding-left: rem(10px);
        }

        .form-group {
            margin-bottom: rem(34px);
        }

        textarea {
            height: rem(113px);
            resize: none;
        }

        button.button-dark {
            margin-bottom: rem(52px);
        }
    }


    @include media-breakpoint-down(sm) {
        margin-bottom: 0 !important;
        .module-header {
            margin-bottom: rem(-40px);
            background: white;
            padding: rem(20px) 0;
            color: $c-fama;

            .container {
                padding: 0;

                .module-title {
                    display: inline-block;
                    background-color: #fff;
                    padding: 0 rem(10px);
                }
            }
        }
        .module-body {
            padding-left: rem(10px);
            padding-right: rem(10px);
            padding-bottom: 0;

            .container {
                padding: rem(40px) 0;
                //border: 1px solid $gray;

                .col-12 {
                    padding: 0;
                }

                .form-group {
                    margin-bottom: rem(25px);
                }
            }

            button.button-dark {
                margin-top: rem(47px);
                margin-bottom: 0;
            }
        }
    }
}

body.famaliving {
    .module {
        .popup-360 .container-360 .header-360 .title-360 {
            color: $c-famaliving;
        }

        @include media-breakpoint-down(sm) {
            .module-tabs .tab-title {
                color: $c-famaliving;
            }
        }

        @include media-breakpoint-up(md) {
            .module-header .module-title {
                color: $c-famaliving;
            }
            .module-next a:hover {
                color: $c-famaliving;
            }

            .module-tabs .tab-title {
                color: $c-famaliving;
            }
        }
    }

    .module-valoraciones .module-body {
        .lateral .lateral-header:after {
            background-color: $c-famaliving;
        }

        .rate .rate-header .photo {
            background-color: $c-famaliving;
        }
    }

    .module-form {
        @include media-breakpoint-down(sm) {
            .module-header {
                color: $c-famaliving;
            }
        }
    }
}
