.stars
{
    display: flex;

    >* {
        width: rem(17px);
        height: rem(16px);
        margin-right: 3px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        opacity: 1;
        transform: translateX(0);
    }
    &.r00 {
        display: none;
        &+* {
            padding-left: 0;
        }
        >*:nth-child(1) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(2) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
    }
    &.r05 {
        >*:nth-child(1) { background-image: url('/css/assets/star-half.svg'); }
        >*:nth-child(2) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
    }
    &.r10 {
        >*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(2) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
    }
    &.r15 {
        >*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(2) { background-image: url('/css/assets/star-half.svg'); }
        >*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
    }
    &.r20 {
        >*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(3) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
    }
    &.r25 {
        >*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(3) { background-image: url('/css/assets/star-half.svg'); }
        >*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
    }
    &.r30 {
        >*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(4) { background-image: url('/css/assets/star.svg'); }
        >*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
    }
    &.r35 {
        >*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(4) { background-image: url('/css/assets/star-half.svg'); }
        >*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
    }
    &.r40 {
        >*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(4) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(5) { background-image: url('/css/assets/star.svg'); }
    }
    &.r45 {
        >*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(4) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(5) { background-image: url('/css/assets/star-half.svg'); }
    }
    &.r50 {
        >*:nth-child(1) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(2) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(3) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(4) { background-image: url('/css/assets/star-active.svg'); }
        >*:nth-child(5) { background-image: url('/css/assets/star-active.svg'); }
    }

}
