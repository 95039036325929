.download-image-list {
	display: flex;
	flex-wrap: wrap;
	width: calc(100% + #{rem(15px)});
	.download-image-item {
		width: calc(20% - #{rem(15px)});
		min-width: calc(20% - #{rem(15px)});
		margin-right: rem(15px);
		margin-bottom: rem(15px);
		text-align: center;
		&.downloaded {
			.download-image-image {
				opacity: 0.5;
			}
			.download-image-text {
				&:before {
					transform: rotate(90deg);
				}
			}
		}
		.download-image-image {
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: rem(150px);
			transition: all $t-fast linear;
		}
		.download-image-text {
			padding: rem(5px) 0;
			color: $gray-dark;
			font-size: rem(15px);
			font-weight: 500;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			&:before {
				content: '';
				display: inline-block;
				width: rem(15px);
				height: rem(15px);
				margin-right: rem(5px);
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				background-image: url('/css/assets/down-arrow.png');
				transition: all $t-fast linear;
			}
		}
	}
	@include media-breakpoint-down(sm)
    {
    	.download-image-item {
    		width: calc(33.333333% - #{rem(15px)});
			min-width: calc(33.333333% - #{rem(15px)});
			.download-image-image {
				height: rem(150px);
			}
		}
    }
	@media (max-width: 500px) {
		.download-image-item {
    		width: calc(50% - #{rem(15px)});
			min-width: calc(50% - #{rem(15px)});
			.download-image-image {
				height: rem(100px);
			}
		}
	}
}