.breadcrumb{
	display: flex;
	align-items: baseline;
	padding: 0;
	margin: 0;
	background-color: transparent !important;
	//padding-bottom: rem(10px);
	.crumb {
		display: flex;
    	align-items: baseline;
		.crumb-inner {
			text-transform: uppercase;
			font-weight: bold;
			font-size: rem(15px);
			color: $gray-dark;
			transition: opacity $t-fast linear;
			&:hover {
				opacity: 0.7;
			}

			h1, .h1{
				font-size: inherit;
				font-family: inherit;
				font-weight: inherit;
				margin: 0;
			}
		}
		&:after {
			content: '';
			display: none;
			margin-left: rem(15px);
			height: rem(10px);
			width: rem(5px);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			background-image: url('/css/assets/simple-arrow.svg');
		}
		&:first-child {
			.crumb-inner {
				font-size: rem(25px);
			}
		}
		&:last-child:not(:first-child) {
			.crumb-inner {
				opacity: 0.5;
				pointer-events: none;
			}
		}
		&:not(:last-child) {
			margin-right: rem(15px);
			&:after {
				display: block;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		padding-bottom: rem(5px);
	 	.crumb {
	 		display: none;
	 		&:last-child {
	 			display: block;
	 			.crumb-inner {
	 				opacity: 1;
	 				font-size: rem(25px);
	 			}
	 		}
	 	}
	}
}
