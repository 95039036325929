@import "../solutions/settings";
.header-detail
{
    position: relative;
    left: 50%;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    margin-top: -$topbar-height;
    z-index: 2;
    transform: translateX(-50%);

    .slider
    {
        max-height: 100vh;
        max-width: 100vw;
        height: $header-detail-height-m;
        width: 100vw;
        overflow: hidden;

        .item
        {
            height: $header-detail-height-m;
            width: 100vw!important;

            img
            {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    @include media-breakpoint-up(md){
        &,
        & .slider,
        & .slider .item
        {
            height: $header-detail-height;

        }       
    }

    .slick-dots
    {
        position: absolute;
        bottom: rem(20px);
        left: rem(70px);
        right: rem(70px);
        text-align: center;
        bottom: rem(86px);
        right: 0;
        width: calc(100vw - #{rem(385px)} - #{$lateral-space});
        left: auto;
        height: rem(50px);
        text-align: left;
        margin: auto;
        white-space: nowrap;
        &:before
        {
            content: '';
            position: absolute;
            top: rem(11px);
            right: 0;
            height: 2px;
            width: 100%;
            background-color: rgba(white, 0.15);
            transition: width $t-normal $easeOutCubic 0s;
        }
        li
        {
            position: relative;
            margin: 0 rem(23px);
            height: 0;
            width: 0;
            transform: translateX(0);
            transition: transform $t-normal $easeOutCubic 0.2s;
            &:first-child
            {
                margin-left: rem(47px);
            }
            $delay: 0.05s;
            @for $i from 1 through 15 {
                &:nth-child(#{$i}) {
                    transition: transform $t-normal $easeOutCubic calc(0.2s + (#{$i} * #{$delay}));
                }
            }
            &:before {
                position: absolute;
                content: "\2022";
                top: calc(50% - 1px);
                left: 50%;
                transform: translate(-50%, -50%);
                font-family: "slick";
                font-size: 0;
                color: rgba(white, 0.15);
                width: 6px;
                height: 6px;
                background-color: rgba(white, 0.15);
                border-radius: 100%;
            }
            button {
                margin-top: -10px;
                margin-left: -10px;
                position: absolute;
                width: 20px;
                height: 20px;
                clip: rect(0px, 20px, 20px, 10px);
                &:before {
                    content: '' !important;
                }
            }
            &.slick-active {
                color: rgba(white, 1);
                &:before {
                    color: rgba(white, 1);
                    background-color: rgba(white, 1);
                }
                button {
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    animation-timing-function: linear;
                    animation-duration: 0.01s;
                    animation-delay: 1.5s;
                    animation-name: close-wrapper;
                    &:before, &:after {
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                        animation-timing-function: linear;
                        position: absolute;
                        content: '' !important;
                        top: 0;
                        left: 1px;
                        width: 18px;
                        height: 18px;
                        border: 1px solid #fff;
                        border-radius: 100%;
                        clip: rect(0px, 10px, 20px, 0px);
                    }
                    &:before {
                        animation-duration: 3s;
                        animation-name: left-spin;
                    }
                    &:after {
                        animation-duration: 1.5s;
                        animation-name: right-spin;
                    }
                }
            }
        }

        @media (min-width: $special-container-max-width)
        {
            width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - #{rem(385px)} - #{$lateral-space});
        }
    }

    .control-fullscreen,
    .control-next,
    .control-prev,
    .control-readmore
    {
        cursor: pointer;
        z-index: 2;
        color: white;
        font-size: rem(11px);
        text-transform: uppercase;
        font-weight: bold;
    }

    .control-fullscreen
    {
        position: absolute;
        top: rem(30px);
        right: rem(22px);

        @include media-breakpoint-up(md){
            right: rem(52px);
            top: calc(#{rem(46px)} + #{rem(60px)}); // + h cabecera
        }
    }

    .control-readmore
    {
        position: absolute;
        right: rem(52px);
        bottom: rem(48px);
    }
    .control-group-prev-next
    {
        position: absolute;
        bottom: rem(8px);
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        padding: 0 3px;



        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div
        {
            margin: 0 3px;
        }

        @include media-breakpoint-up(md)
        {
            bottom: rem(48px);
            width: auto;
            padding: 0;
            justify-content: inherit;
        }
    }

    &.is-fullscreen
    {
        .control-readmore
        {
            display: none !important;
        }
        .slick-dots
        {
            width: auto;
            left: 0;
            text-align: center;

            li:first-child
            {
                margin-left: rem(23px);
            }
        }
        .control-group-prev-next
        {
            margin-right: auto;
            margin-left: auto;
            left: 50%;
            right: auto;
        }
    }
    @include media-breakpoint-down(sm)
    {
        padding-top: $topbar-height-sm;
        margin-top: 0;
        height: 60vh;
        .control-fullscreen {
            top: rem(25px);
        }
        .slider {
            height: 100%;
            margin-bottom: 0;
            .slick-list, .slick-track, .slick-slide, .slick-slide > div, .item {
                height: 100%;
            }
            .slick-dots {
                width: calc(100% - #{rem(100px)});
                height: unset;
                bottom: rem(30px);
                left: 50%;
                transform: translate(-50%, 50%);
                text-align: center;
                &:before {
                    display: none;
                }
                li {
                    margin: 0;
                    margin-right: rem(20px);
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.module-valoraciones {
    padding-top: rem(25px);
}

.slider-handler {
    width: rem(385px);
    padding: 0 !important;
    @include media-breakpoint-down(sm)
    {
        width: 100vw;
        min-width: 100vw;
        max-width: 100vw;
        padding: 0 !important;
        left: 50%;
        transform: translateX(-50%);
    }
}

.slider-container, 
.slider-container.col-12 {
    position: relative;
    width: calc(100% - #{rem(385px)});
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        height: 100%;
        width: calc(100vw - #{rem(325px)});
        background-color: $gray;
    }
    .module-tabs-nav {
        width: 100vw;
        max-width: calc(100vw - #{rem(325px)} - #{rem(98px)});
        overflow-x: auto;
        padding-left: rem(98px);
        margin-left: rem(-98px);

        @include media-breakpoint-down(sm){
            max-width: 100%;
            margin-left: 0;
            padding-left: 0;
        }

        >.item {
            &:last-child {
                margin-right: rem(56px);
            }
        }
    }
    @media (min-width: $special-container-max-width)
    {
        .module-tabs-nav {
            max-width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - #{rem(325px)} - #{rem(98px)});
        }
    }
    @include media-breakpoint-down(sm){
        &, 
        &.col-12{
            width: 100%;
            overflow: hidden;
            width: 100vw;
            min-width: 100vw;
            max-width: 100vw;
            padding: 0;
            left: 50%;
            transform: translateX(-50%);
            &:before {
                display: none;
            }
        }
    }
}

@keyframes right-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes left-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes close-wrapper {
  to {
    clip: rect(auto, auto, auto, auto);
  }
}
 
.ballon-top,
.ballon-bottom {
    position: absolute;
    height: rem(442px);
    width: rem(442px);
    background-color: $white;
    border-radius: 100%;
    z-index: -1;
    transition: transform $t-fast $easeOutCubic;
}
.ballon-top
{
    top: rem(-121px);
    left: rem(78px);
}

.ballon-bottom
{
    top: rem(455px);
    right: rem(-60px);
}

.atugusto-img {
    width: calc(100vw - #{rem(385px)} - #{$lateral-space} - #{rem(96px)});

    img {
        width: 100%;
    }

    @include media-breakpoint-up($special-container-max-width)
    {
        width: calc(100vw - ((100vw - #{$special-container-max-width}) / 2) - #{rem(385px)} - #{$lateral-space} - #{rem(96px)});
    }
    @include media-breakpoint-down(sm)
    {
        width: calc(100vw - #{rem(20px)});
    }
}

#tipologias-de-sofa {
    .module-tab {
        margin-left: calc(-#{rem(96px)} - #{rem(25px)});
        width: calc(100% + #{rem(96px)} + #{rem(25px)});
    }
}

#caracteristicas {
    .module-body {
        .module-wrap {
            >.row {
                flex-wrap: wrap;
            }
        }
    }
}

.simulation-button-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    @include media-breakpoint-down(sm)
    {

    }
}

.select-gb-region-wrapper,
.select-gb-region-wrapper-xs {
    text-align: center;
    h3 {
        margin-bottom: 15px;
    }
    input {
        margin-right: 5px;
    }
    button {
        margin-top: 15px;
    }
}

@media(max-width: $screen-sm-max) {
    .flex-max-sm-column {
        flex-direction: column;
    }
    .order-first-xs {
        order: -1;
    }
    .select-gb-region-wrapper-xs {
        margin-left: 0 !important;
        margin-bottom: 15px !important;
    }
}


