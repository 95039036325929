// Colors

$input-border-radius:  0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$blue: #3a80be; //modified
$indigo: #6574cd;
$purple: #9561e2;
//$pink: #c61083;
$red: #e3342f;
$orange: #ff8c41; //modified
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$white: #ffffff;
$gray: #f3f3f3;
$gray-dark: #324b4e;
$c-fama: #009aa7;
$c-famaliving: #c03d8b;
$fl-rose: #c70087;
$c-gray2: #cfdae1;

// Body
//$body-bg: $white;
//$body-color: $gray-dark;

$grid-breakpoints: (
	xs: 0,
  	sm: 576px,
  	md: 769px,
 	lg: 992px,
  	xl: 1200px
);

//Container
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1208px,
);

//Container_2021

$sm-width: 768px;
$md-width: 991px;
$lg-width: 1441px;
$extra-width: 1920px;

//$special-container-max-width: 1280px;
$special-container-max-width: 1400px;

// Typography
$line-height-base: 1;
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-base: 1.3rem !default; //10px
//$font-size-base: 0.5rem !default; //10px

$f-size-normal-body: rem(15px);
$f-size-big-title: rem(35px);
$f-size-title: rem(31px);
$f-size-medium: rem(25px);
$f-size-small: rem(13px);
$f-size-very-small: rem(11px);

//_2021
$f-size-normal-body_2021: 1.5rem;
$f-size-big-title_2021: 3.5rem;
$f-size-title_2021: 3.1rem;
$f-size-medium_2021: 2.5rem;
$f-size-small_2021: 1.3rem;
$f-size-very-small_2021: 1.1rem;


//Slick
$slick-dot-size: 8px;
$slick-dot-color: $white;
$slick-dot-color-active: $slick-dot-color;
$slick-opacity-default: 1;
$slick-opacity-not-active: 0.5;


//transitions
$t-fast: 0.2s;
$t-normal: 0.4s;
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);

///Components variables
$topbar-height: rem(60px);
$topbar-height-sm: rem(60px);
$lateral-space: rem(50px);
$lateral-space-sm: rem(10px);
$header-detail-height: 100vh;
$header-detail-height-m: calc(100vh - #{$topbar-height});

// Vendor
$slick-font-path: '/fonts/vendor/slick-carousel/slick/';

//FAMA_2021___FAMALIVING_2023
$colorCorporative:#c70087; 
$line-gray: rgba(#3f6574, 0.5);
$pink:#c95a9c;
$err: #e95950;
$bg-grey: #f3f6f7;
$colorBlack: #333333;
$colorGray: #999999;

